import React from "react";
import { Link } from "gatsby";

import { Container, Section, Flex, Box } from "tailwind-react-ui";

const SecondaryNav = ({ items }) => {
  return (
    <Section
      bg="gray-light"
      p="0"
      className="bg-gray-light p-0 shadow block xl:hidden"
      data-component-type="secondary-navigation"
    >
      <Container>
        <Flex
          wrap
          items="baseline"
          justify="center"
          className="flex-wrap items-baseline justify-center w-full secondary-nav"
        >
          <>
            {items.map((item, i) => (
              <Box key={i}>
                <Link
                  to={`/${item.relatedEntry[0].uri}`}
                  className={`transition duration-500 ease-in-out active:text-white focus:text-white hover:text-white focus:bg-primary hover:bg-primary p-3 sm:py-4 sm:px-5 block text-sm lg:text-sm ${
                    i === 0 ? null : ""
                  }`}
                  activeClassName="active focus:text-white hover:text-white text-white bg-primary"
                >
                  {item.relatedEntry[0].title}
                </Link>
              </Box>
            ))}
          </>
        </Flex>
      </Container>
    </Section>
  );
};

export default SecondaryNav;
