import * as React from "react"
import { 
	graphql, 
	// Link 
} from "gatsby"

// Layout
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Matrix from "../../components/Matrix"
import { buildFluidImageData } from '@imgix/gatsby'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Tailwind React UI Components
import { 
	Container,
	Title,
	Section,
	Text
} from 'tailwind-react-ui'

// Custom Components
import GreenCarousel from '../../components/Matrix/types/Independent/GreenCarousel'
import PageBanner from '../../components/PageBanner'
import SecondaryNav from '../../components/SecondaryNav'

// Hard-coded image imports
import TriangleGY from '../../images/patterns/patterns-triangles_greenyellow.svg'

// GraphQL
export const query = graphql`
  query ExplorePageQuery {
    entry: craftExploreExploreEntry {
		id
		remoteId
		title
		typeHandle
		uri
		url
		subtitle
		featureImage { ...UploadAssetFragment }
		metaTitle
		metaDescription
		metaKeywords
		metaImage { ...UploadAssetFragment }
		heading {
			... on Craft_heading_heading_BlockType {
				text
				hierarchy
				colour
			}
		}
		richText
		bodyContent {
			... BodyContentFragment
		}
		greenContentSlider {
			... on Craft_greenContentSlider_slide_BlockType {
				heading
				image { ...UploadAssetFragment }
				text
				buttonText
				buttonUrl
				relatedEntry { uri }
				warningText
			}
		}
	}
	siteInfo: craftSiteInformationGlobalSet {
		...SiteInfoFragment
	}
	navigation: allCraftMainNavigationRelatedEntryEntry(filter: {relatedEntry: {elemMatch: {sectionId: {eq: 19}}}}) {
		group(field: relatedEntry___sectionId) {
			nodes {
				relatedEntry {
					title
					uri
				}
			}
		}
	}
}
`

const truncateString = (string = '', maxLength = 54) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const ComeExplore = ({ data: { entry, siteInfo, navigation } }) => {
	
	// secondary navigation
	const secondaryNav = navigation.group[0].nodes

	const subtitle = entry.subtitle || siteInfo.siteTitle
	const [ siteImage ]  = siteInfo.siteImage
	const [ featureImage = siteImage ] = entry.featureImage
	const metaImage = featureImage || entry.metaImage || siteInfo.siteImage
	const metaDescription =  truncateString((entry.metaDescription || entry.summary || siteInfo.siteDescription).replace(/(<([^>]+)>)/gi, ""), 159)
	const metaTitle = truncateString(`${entry.metaTitle || entry.displayTitle || entry.title}`, 53)
	const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300
		}
    )

	return (
		<Layout location={entry.url} title={metaTitle}>
			<Seo
				title       = {metaTitle}
				description = {metaDescription}
				keywords    = {metaKeywords}
				image       = {metaImageFormatted.src}
				url         = {entry.url}
				pathname	= {`/${entry.uri}`}
			/>
			<h1 style={{fontSize: 0, color: 'transparent'}} className="sr-only">{metaTitle}</h1>

			<Section p="0" className="relative">
				<PageBanner
					title    = {entry.title}
					subtitle = {subtitle}
					image    = {featureImage}
				/>
			</Section>

			<SecondaryNav items={secondaryNav} />
			
			{entry.bodyContent.length ? <Section p="0" className="secondary-page relative">
				<Container className="xl:w-4/5 pt-14 pb-8">
					<Matrix blocks={entry.bodyContent} />
				</Container>
			</Section>: '' }

			<Section bg="gray-light"  p="0">
				<Container className="xl:w-3/4 py-14 lg:py-20">
					{entry.heading[0] ? 
					<Title font="heading" level={Number(entry.heading[0].hierarchy)} text={entry.heading[0].colour} className=" relative flex justify-center flex-start">
						{entry.heading[0].text}
						<TriangleGY className="ml-6 -mt-1" alt="Triangles" />
					</Title>: '' }
					{entry.richText ? <Text className="text-center text-md" dangerouslySetInnerHTML={{__html: entry.richText}}/>: '' }
					{entry.greenContentSlider ?
						<GreenCarousel
							items={entry.greenContentSlider}
						/>
					: null}
				</Container>
			</Section>
		</Layout>
	)
}

export default ComeExplore