import React from 'react'
import { buildFluidImageData } from '@imgix/gatsby'

import { 
	Box,
	Container,
	Title,
	Section,
	Subtitle,
	// propTypes
	 
} from 'tailwind-react-ui'

// Image imports
import TriangleGY from '../images/patterns/patterns-triangles_greenyellow.svg'
import DotsGreen from '../images/patterns/patterns-dots_green.svg'

const PageBanner = (props) => {
	const image = props.image
	const padding = props.padding ?? 'py-44' 
	const imageFormatted = buildFluidImageData(
		`${image.url}`,
		{
			ar: 21/9, // required
			auto: ['format', 'compress'],
			sizes: `2000px, 1440px, 1280px, 900px, 640px, 400px`, // optional, but highly recommended
			width: 2100,
			height: 900
		}
    )

	return (
		<Section className="p-0 bg-gray-200 flex items-center justify-center text-center relative flex-col" 
			p="0" bg="gray-200" data-component-type="page-banner"
			style={{
				backgroundImage: `url(${imageFormatted.src})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPositionY: image.focalPoint ? `${image.focalPoint[1] * 100}%` : 'center',
				backgroundPositionX: image.focalPoint ? `${image.focalPoint[0] * 100}%` : 'center',
				ar: 3
			}}>
			<div className="bg-gradient-to-t from-black to-transparent absolute top-0 left-0 w-full h-full opacity-30"></div>
			<Container className={`py-10 lg:${padding} relative`}>
				<Box m="auto" className="m-auto lg:w-5/6">
					<Subtitle level={4} text="white" m="3" className="text-white m-3 break-all text-lg text-shadow-lg font-heading" font="heading">{props.subtitle}</Subtitle>
					<Title level={1} text="white" font="heading" className={`text-white font-heading break-all text-2xl sm:text-3xl leading-none text-shadow-lg ${props.title.length < 25 ? 'text-4xl sm:text-5xl md:text-6xl lg:text-7xl' : 'md:text-5xl'}`}>{props.title}</Title>
				</Box>
			</Container>
			<TriangleGY className="absolute left-0 top-full -mt-5" alt="Triangle Green Yellow Pattern" />
			<DotsGreen className="hidden md:block absolute right-10 top-full -mt-20" alt="Dots Green Pattern"  />
		</Section>
	)
}

export default PageBanner