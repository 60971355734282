import React from 'react'

// React multi carousel
import Carousel from 'react-multi-carousel'
import ImgixFluidImage from "../../../ImgixFluidImage"
import Download from "../BodyContent/Download"
// import 'react-multi-carousel/lib/styles.css'

// Tailwind React UI
import { 
	Box,
	Card,
	CardBody,
	FillButton,
	Flex,
	Text,
	Title
} from 'tailwind-react-ui'

import ArrowLeft from "../../../../images/icons/icon-arrow_left_green.svg"
import ArrowRight from "../../../../images/icons/icon-arrow_right_green.svg"
import Hiker from "../../../../images/patterns/patterns-hikers.svg"

const GreenCarousel = ({items}) => {

	const CustomLeft = ({ onClick }) => (
		<button className="absolute right-full -mr-10 arrow left px-2 py-3 shadow z-10 top-1/4 -mt-14 bg-white" onMouseDown={onClick} aria-label="Previous slide">
			<ArrowLeft alt="Previous slide" />  
		</button>
	)
	const CustomRight = ({ onClick }) => (
		<button className="absolute left-full -ml-10 arrow right px-2 py-3 shadow z-10 top-1/4 -mt-14 bg-white" onMouseDown={onClick} aria-label="Next slide">
			<ArrowRight alt="Next slide" />  
		</button>
	)

	const responsive = {
		desktop: {
			breakpoint: {
				max: 2500,
				min: 1200
			},
			items: 1,
		},
		tablet: {
			breakpoint: {
				max: 1200,
				min: 600
			},
			items: 1,
		},
		mobile: {
			breakpoint: {
				max: 600,
				min: 0
			},
			items: 1,
		} 
	}

	const CustomDot = ({ onMove, index, onClick, active }) => {
		// onMove means if dragging or swiping in progress.
		// active is provided by this lib for checking if the item is active or not.
		if (index <= 6) {
			return (
				<>
					<li key={index} className={`transition duration-300 ease-in-out bg-white text-sm w-full text-center hover:text-white active:text-white mb-2 py-2 px-3 border focus:bg-green hover:bg-green active:bg-green hover:border-green focus:border-green active:border-green rounded-none cursor-pointer font-display ${active ? "active border-green bg-green text-white" : "inactive"}`} >
						<button className="outline-none uppercase" onMouseDown={() => onClick()}>{items[index].heading}</button>
					</li>
				</>
			)
		}
		return null
	}

	return (
		<>
			<Carousel
				arrows
				autoPlay	= {false}
				centerMode	= {false}
				containerClass	 = {`relative -mx-3 greenSlider`}
				customDot		 = {<CustomDot />}
				customLeftArrow	 = {<CustomLeft />}
				customRightArrow = {<CustomRight />}
				data-component-type="green-carousel"
				dotListClass	 = "hidden top-1/3 hidden bg-white xl:flex flex-wrap right justify-end right-14 bottom-auto ml-auto w-1/4 greenSlider mt-20"
				draggable	  = {false}
				focusOnSelect = {false}
				infinite
				itemClass = ""
				keyBoardControl
				minimumTouchDrag		 = {150}
				renderButtonGroupOutside = {false}
				renderDotsOutside		 = {false}
				responsive = {responsive}
				showDots
				sliderClass		= "py-5 sm:py-10"
				slidesToSlide	= {1}
				swipeable 
			>
				{items.map((card, i) => (
					<Card key={i} shadow className="card rounded-none flex flex-col sm:h-full mx-3 shadow-md border relative" p="0">
						<Box w="full" className="mb-2 border-gray-300 overflow-hidden">
							<ImgixFluidImage className="hidden xl:block w-full bg-gray-light" alt={card.image[0].imageAlt ?? card.image[0].title ?? card.image[0].filename.replace(/[^a-z0-9+]+/gi, ' ')} imageUrl={card.image[0].url} ar={4.26} focalPoint={card.image[0].focalPoint} /> 
							<ImgixFluidImage className="block xl:hidden w-full bg-gray-light" alt={card.image[0].imageAlt ?? card.image[0].title ?? card.image[0].filename.replace(/[^a-z0-9+]+/gi, ' ')} imageUrl={card.image[0].url} ar={3} focalPoint={card.image[0].focalPoint} /> 
						</Box>
						<CardBody className="px-8 pt-6 pb-0 xl:w-2/3">
							<Flex>
								<Box className="pt-3 sm:px-3">
									<Title level={3} font="heading" className="font-heading text-black lg:text-4xl">{card.heading}</Title>
									<div data-component-type="rich-text" dangerouslySetInnerHTML={{__html: card.text}} />
								</Box>
							</Flex>
						</CardBody>
						<Flex wrap className="mt-auto text-left px-8 pb-8 items-end">
							{card.relatedEntry[0] || card.buttonUrl ? 
								<FillButton 
									font="display"
									data-type="button" bg="white" 
									bg-hocus="secondary" 
									text="primary" text-hocus="primary"
									is="a" href={card.relatedEntry[0] ? '/' + card.relatedEntry[0].uri : card.buttonUrl} 
									className={`transition duration-300 ease-in-out whitespace-nowrap mb-3 px-3 lg:px-8 py-3 rounded-full border-primary focus:border-secondary hover:border-secondary`}>{card.buttonText ?? 'Find out more'}
								</FillButton>
							: '' }
							{card.warningText ? 
								<>
									<Text is="p" className="flex items-baseline text-red text-sm md:px-6"><span className="text-center block w-5 h-5 border border-red rounded-full mr-2 leading-0 font-bold">!</span>{card.warningText}</Text>
									<Hiker alt="Hiker icon" className="hidden lg:block mt-3 ml-auto self-end right-12" />
								</> 
							: ''}
							{card.download ? card.download.map((file, i) => (
								<>
									<Download
										key={i}
										classes="text-sm text-bold" block={{
										file: '/' + file.uri,
										heading: file.heading ?? file.title,
										size: false
									}} />
								</>
							)) : ''}
						</Flex>
					</Card>
				))}
			</Carousel>
		</>
		
	)
}

export default GreenCarousel